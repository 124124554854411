import { withZod } from '@remix-validated-form/with-zod'
import { ValidatedForm, useField, useIsSubmitting } from 'remix-validated-form'
import { z } from 'zod'
import { LoadingCircle } from '~/components'
import { Button, Input, Label } from '~/components/ui'

export const passwordLoginValidator = withZod(
  z.object({
    variant: z.string(),
    email: z.string().email('Enter a valid email').toLowerCase(),
    password: z.string().min(1, 'Enter a password'),
  })
)
export function PasswordLoginForm() {
  const formId = 'password-login-form'
  const { error: emailError } = useField('email', { formId })
  const { error: passwordError } = useField('password', { formId })
  const isSubmitting = useIsSubmitting(formId)

  return (
    <ValidatedForm
      method="post"
      action="/login"
      id={formId}
      validator={passwordLoginValidator}>
      <input type="hidden" name="variant" value="password" />
      <div className="mb-4">
        <Label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-foreground">
          Email address
        </Label>
        <div className="mt-2">
          <Input id="email" name="email" type="email" autoComplete="email" />
        </div>
        {emailError && (
          <p className="mt-2 text-sm text-red-500">{emailError}</p>
        )}
      </div>
      <div className="mb-4">
        <Label
          htmlFor="password"
          className="block text-sm font-medium leading-6 text-foreground">
          Password
        </Label>
        <div className="mt-2">
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete="password"
          />
        </div>
        {passwordError && (
          <p className="mt-2 text-sm text-red-500">{passwordError}</p>
        )}
      </div>

      <Button
        className="button flex w-full justify-center"
        disabled={isSubmitting}
        type="submit">
        {isSubmitting ? 'Logging In' : 'Log In'}
        {isSubmitting && <LoadingCircle className="mx-2 h-5 w-5" />}
      </Button>
    </ValidatedForm>
  )
}
